
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildDate": "2025-01-23T14:48:33.274Z",
  "AZURE_CLIENT_ID": "dc28c72c-cbf1-4e33-aede-ce5fe276af28",
  "AZURE_TENANT_ID": "fb0af7d1-8a83-48bc-8d2d-cd92034c79d4",
  "DEV_ENDPOINT": "https://api.test.advisor.securitas.com",
  "SENTRY_DSN": "https://2c2737b93ea0cc3b4b4f4f86fda3ac77@o4508301541834752.ingest.de.sentry.io/4508330348642384",
  "SENTRY_ENVIRONMENT": "Test",
  "APP_INSIGHTS_CONNECTION_STRING": "InstrumentationKey=dbad4c53-a962-4690-9b5c-11c28bdd4e61;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=e41c3cbf-747e-4da8-a17f-70035ed56892",
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
