<script setup lang="ts">
defineProps<{
  contact?: ContactPerson
  user?: User
}>()

const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)
</script>

<template>
  <div class="grid">
    <div class="grid grid-cols-3 items-center gap-spacing-lg">
      <img
        v-if="selectedProject?.image"
        :src="selectedProject?.image"
        class="max-h-40 w-full rounded-3xl object-cover">
      <img
        v-else
        src="/assets/SecuritasHQ.jpg"
        class="h-[170] rounded-3xl object-cover">
      <div class="flex h-full flex-col gap-spacing-sm2">
        <p class="secu-headline-bold">
          {{ $t('client_contact') }}
        </p>
        <div
          class="secu-body-small flex h-full flex-col gap-spacing-md break-all rounded-lg bg-secondary-gray-300 p-spacing-sm">
          <div class="space-y-spacing-sm2">
            <p class="secu-body-bold">
              {{ contact?.name ?? 'N/A' }}
            </p>
          </div>
          <div class="space-y-spacing-sm2">
            <div v-if="contact?.businessPhone" class="flex gap-x-spacing-xs">
              <SvgoPhone
                class="size-5 stroke-black stroke-[1.5] group-hover:stroke-accent-purple-300 group-hover:stroke-2" />
              <p>{{ contact?.businessPhone ?? 'N/A' }}</p>
            </div>
            <div v-if="contact?.email" class="flex gap-x-spacing-xs">
              <SvgoEmail
                class="size-5 stroke-black stroke-[1.5] group-hover:stroke-accent-purple-300 group-hover:stroke-2" />
              <p>{{ contact?.email ?? 'N/A' }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex h-full flex-col gap-spacing-sm2">
        <p class="secu-headline-bold">
          {{ $trp($t('securitas_contact')) }}
        </p>
        <div
          class="secu-body-small flex h-full flex-col gap-spacing-md break-all rounded-lg bg-secondary-gray-300 p-spacing-sm">
          <div class="space-y-spacing-sm2">
            <p class="secu-body-bold">
              {{ user?.displayName ?? 'N/A' }}
            </p>
            <p v-if="user?.jobTitle" class="mb-spacing-sm2">
              {{ user?.jobTitle ?? 'N/A' }}
            </p>
          </div>
          <div class="space-y-spacing-sm2">
            <div v-if="user?.mobilePhone" class="flex gap-x-spacing-xs">
              <SvgoPhone
                class="size-5 stroke-black stroke-[1.5] group-hover:stroke-accent-purple-300 group-hover:stroke-2" />
              <p>{{ user?.mobilePhone ?? 'N/A' }}</p>
            </div>

            <div v-if="user?.mail" class="flex gap-x-spacing-xs">
              <SvgoEmail
                class="size-5 stroke-black stroke-[1.5] group-hover:stroke-accent-purple-300 group-hover:stroke-2" />
              <p>{{ user?.mail ?? 'N/A' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-fit">
      <p class="secu-headline-bold">
        {{ $t('site_assessment') }}
      </p>
      <p class="secu-body-small text-justify">
        {{ $trp($t('site_assessment_info')) }}
      </p>
    </div>
    <div class="h-full">
      <p class="secu-headline-bold">
        {{ $t('disclaimer_title') }}
      </p>
      <p class="secu-body-small text-justify">
        {{ $trp($t('disclaimer')) }}
      </p>
    </div>
  </div>
</template>
