<script setup lang="ts">
import { exportToPDF } from '#imports'
import { createCombinedAddress } from '@/utils/addressUtils'

const { currentUser } = storeToRefs(useAuthStore())
const projectStore = useProjectStore()
const { fetchProjects, loadScenarioImages } = projectStore
const {
  selectedProject,
  shouldRefetch,
  scenarioImages,
  selectedArea,
  selectedRisk,
  selectedScenario,
} = storeToRefs(projectStore)
const { locales, locale, t } = useI18n()

const date = new Date()
const formDate = date.toLocaleDateString(
  locales.value.find(code => code.code === locale.value)?.iso,
  {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
)

const { $tx } = useNuxtApp()

const pdfContent = ref()
const contentsTable = ref<ReportPageInfo[][]>([])

const summaryItems = ref<Area[][]>([])
const riskAssessmentItems = ref<PdfRisk[][]>([])
const pages = ref<ReportPageInfo[]>([])
const filteredAreas = ref<Area[]>([])

const loadingImages = ref(false)
const fetching = ref(false)
const router = useRouter()

const exportingPDF = ref(false)

function generatePDF() {
  const fileName = 'report.pdf'
  exportToPDF(fileName, pdfContent.value, exportingPDF)
}

const PAGE_SIZE_TOC = 10

const totalsSummary = computed(() => {
  // Calculate the total number of risks, scenarios, and solutions
  const totals = { areas: 0, risks: 0, scenarios: 0, solutions: 0 }
  totals.areas = filteredAreas.value?.length ?? 0
  filteredAreas.value
    ?.flatMap(({ risks }) => risks as Risk[])
    .forEach(({ scenarios }) => {
      totals.risks += 1
      totals.scenarios += scenarios?.length ?? 0
      totals.solutions
        += scenarios?.flatMap(({ solutions }) => solutions).length ?? 0
    })
  return totals
})

function convertToSummary(
  limit: number = 12,
  limitCap: number = 17,
  itemsArray: Ref,
  areas: Area[],
  scenarioCount: number = 1.5,
) {
  let currentPage: Area[] = []
  let currentPageTotal = 0
  let isIndexPage = true

  // Helper function to determine the page limit based on the current page type
  function getCurrentLimit() {
    return isIndexPage ? limit : limitCap
  }

  // Helper function to start a new page if the current page exceeds the limit
  function checkPageLimit() {
    if (currentPageTotal >= getCurrentLimit()) {
      itemsArray.value.push(currentPage)
      currentPage = []
      currentPageTotal = 0
      isIndexPage = false // From now on, we are on non-index pages
      return true
    }
    return false
  }

  // Helper function to add a scenario (whether it has solutions or not)
  function addScenario(scenario: Scenario, risk: Risk, area: Area) {
    // if a new page is created, add the area and risk into the new currentPage before pushing the scenario
    if (checkPageLimit()) {
      currentPage.push({ ...area, risks: [{ ...risk, scenarios: [] }] })
      risk = currentPage.at(-1)?.risks?.at(-1) as Risk
    }
    if (!risk.scenarios)
      risk.scenarios = []
    risk.scenarios.push({ ...scenario, solutions: [] })
  }

  // Helper function to add a risk (whether it has scenarios or not)
  function addRisk(risk: Risk, area: Area) {
    // if a new page is created, add the area  into the new currentPage before pushing the risk
    if (checkPageLimit()) {
      currentPage.push({ ...area, risks: [] })
      area = currentPage.at(-1) as Area
    }
    if (!area.risks)
      area.risks = []
    area.risks.push({ ...risk, scenarios: [] })
  }

  // Helper function to add an area
  function addArea(area: Area) {
    checkPageLimit()
    currentPage.push({ ...area, risks: [] })
  }

  // Helper function to process each solution and paginate
  function addSolution(
    solution: Solution,
    scenario: Scenario,
    risk: Risk,
    area: Area,
  ) {
    // if a new page is created, add the area, risk, scenario into the new currentPage before pushing the solution
    if (checkPageLimit()) {
      currentPage.push({
        ...area,
        risks: [{ ...risk, scenarios: [{ ...scenario, solutions: [] }] }],
      })
      scenario = (
        currentPage.at(-1)?.risks?.at(-1)?.scenarios as Scenario[]
      )?.at(-1) as Scenario
    }
    if (!scenario.solutions)
      scenario.solutions = []
    scenario.solutions?.push(solution)
  }

  // Process each area, risk, scenario, and solution
  areas.forEach((area) => {
    addArea(area)
    const risks = area.risks || []
    risks.forEach((risk) => {
      const lastArea = currentPage.at(-1) as Area
      addRisk(risk, lastArea)
      const scenarios = risk.scenarios || []

      scenarios.forEach((scenario) => {
        const lastArea = currentPage.at(-1) as Area
        const lastRisk = (lastArea.risks as Risk[]).at(-1) as Risk
        addScenario(scenario, lastRisk, lastArea)
        const solutions = scenario.solutions || []

        solutions.forEach((solution) => {
          const lastArea = currentPage.at(-1) as Area
          const lastRisk = (lastArea.risks as Risk[]).at(-1) as Risk
          const lastScenario = (lastRisk.scenarios as Scenario[]).at(
            -1,
          ) as Scenario
          addSolution(solution, lastScenario, lastRisk, lastArea)
          currentPageTotal += 1
        })

        // Process scenarios without solutions
        if (solutions.length === 0) {
          currentPageTotal += scenarioCount
        }
        else {
          // this is for risk assement page that when there is a new Scenario, this is to mitigate the space between solutions belonging to different scenarios
          currentPageTotal += 1.5 - scenarioCount
        }
      })

      // If risk has no scenarios, add page count 1.5
      if (scenarios.length === 0) {
        currentPageTotal += 1.5
      }
    })

    // If area has no risks, add page count 1.5
    if (risks.length === 0) {
      currentPageTotal += 1.5
    }
  })

  // Push the last page if there are remaining items
  if (currentPage.length > 0) {
    itemsArray.value.push(currentPage)
  }
}

function calculateTableOfContentPages(): number {
  const itemsPerPage = PAGE_SIZE_TOC
  const tableOfContentItems = 5 + totalsSummary.value.risks

  // Calculate the total number of pages
  return Math.ceil(tableOfContentItems / itemsPerPage)
}

function calculateOverviewPages(): number {
  return summaryItems.value.length
}

function getSiteAssessmentPages() {
  const siteAssessmentPages = [{ title: t('site_assessment'), pageAmount: 0 }]
  riskAssessmentItems.value.flat().forEach((item) => {
    siteAssessmentPages.push({
      title: `${$tx(item.areaName)} - ${$tx(item.riskType.name)}`,
      pageAmount: item.scenarioPagination.length,
    })
    const scenarioPages = getScenariosPages(item.scenarios ?? [])
    siteAssessmentPages.push(...scenarioPages)
  })
  return siteAssessmentPages
}

function getScenariosPages(scenarios: Scenario[]) {
  const scenariosPages = [] as ReportPageInfo[]
  scenarios.forEach((scenario) => {
    scenariosPages.push({
      title: `Scenario - ${scenario.id}`,
      pageAmount: (scenario?.images?.length ?? 0) > 2 ? 2 : 1,
    } as ReportPageInfo)
  })
  return scenariosPages
}

function initializePages() {
  let currentPageNumber = 1
  pages.value = [
    {
      title: 'Index page',
      pageAmount: 1,
    },
    {
      title: 'Info page',
      pageAmount: 1,
    },
    {
      title: 'Table of contents',
      pageAmount: calculateTableOfContentPages(),
    },
    {
      title: t('site_assessment_method'),
      pageAmount: 1,
    },
    {
      title: t('risk_severity_definitions'),
      pageAmount: 1,
    },
    {
      title: t('risk_matrix'),
      pageAmount: 1,
    },
    {
      title: t('overview'),
      pageAmount: calculateOverviewPages(),
    },
  ]
    .concat(getSiteAssessmentPages())
    .map((page) => {
      const pageWithNumber = { ...page, pageNumber: currentPageNumber }
      currentPageNumber += page.pageAmount
      return pageWithNumber
    })
}

function initializeContentsTable() {
  const contents = pages.value
    .slice(3)
    .filter(page => !page.title.startsWith('Scenario -'))
  contentsTable.value = paginateArray(contents, PAGE_SIZE_TOC)
}

function paginateArray<T>(array: T[], pageSize: number): T[][] {
  const paginatedArray: T[][] = []

  for (let i = 0; i < array.length; i += pageSize) {
    const chunk = array.slice(i, i + pageSize)
    paginatedArray.push(chunk)
  }

  return paginatedArray
}

function getPageNumber(title: string) {
  const page = pages.value.find(page => page.title === title)
  if (!page) {
    return 0
  }
  return page.pageNumber
}

async function loadAllScenarioImages() {
  loadingImages.value = true

  const fetchImagesPromises = selectedProject.value?.areas
    ?.flatMap((area) => {
      selectedArea.value = area
      return area?.risks?.flatMap((risk) => {
        selectedRisk.value = risk
        return risk.scenarios?.map((scenario) => {
          selectedScenario.value = scenario
          return loadScenarioImages(fetching)
        })
      })
    })
    .filter(Boolean)
  if (fetchImagesPromises)
    await Promise.all(fetchImagesPromises)

  loadingImages.value = false
}

function getRiskAssessmentItems() {
  filteredAreas.value.forEach((area) => {
    ;(area.risks ?? []).forEach((risk) => {
      // make sure the tempArea only contain one risk at one time
      const tempArea = { ...area, risks: [{ ...risk }] }
      convertToSummary(4, 13, riskAssessmentItems, [tempArea], 1.25)
    })
  })

  riskAssessmentItems.value = Object.values(
    riskAssessmentItems.value
      .flat() // Flatten the outer array
      .reduce((acc: Record<string, PdfRisk>, area: Area) => {
        area.risks?.forEach((risk: Risk) => {
          const riskWithArea = {
            ...risk,
            areaName: area.name || area.areaType?.name,
            scenarioPagination: [risk.scenarios],
          } as PdfRisk
          const riskId = risk.id as string
          if (acc[riskId]) {
            // Merge scenarios if the risk ID already exists
            acc[riskId].scenarios = [
              ...(acc[riskId].scenarios as []),
              ...(riskWithArea.scenarios as []),
            ]
            acc[riskId].scenarioPagination.push(
              riskWithArea.scenarios as Scenario[],
            )
          }
          else {
            // Otherwise add the risk to the accumulator
            acc[riskId] = riskWithArea
          }
        })

        return acc
      }, {}),
  ).reduce((result: PdfRisk[][], risk: PdfRisk) => {
    // Push each merged risk into a new array (restoring the two-dimensional structure)
    result.push([risk]) // Single-element arrays
    return result
  }, [])
}

function backToProject() {
  navigateTo(`/projects/${selectedProject.value?.id}`)
}

onMounted(async () => {
  if (!selectedProject?.value || shouldRefetch.value) {
    await fetchProjects(fetching, router.currentRoute.value.params.id as string)
  }

  // remove the areas that don't have any risks and risks that don't have any scenarios
  filteredAreas.value = removeIrrelevantRisksAndAreasAndSort(
    selectedProject.value?.areas as Area[],
  )

  // get summary items for overview part
  convertToSummary(21, 23, summaryItems, filteredAreas.value || [])

  // get risk assesment items for risk introducion part
  getRiskAssessmentItems()

  // initialize page structures and table of contents
  initializePages()
  initializeContentsTable()

  // load all scenario iamges
  await loadAllScenarioImages()
})
</script>

<template>
  <div v-if="(!selectedProject && fetching) || loadingImages">
    <Loading class="page-center" />
  </div>
  <div v-else class="flex flex-col overflow-scroll bg-white p-spacing-md">
    <div class="mx-spacing-md flex items-center justify-between">
      <SButton
        :text="$t('back')"
        class="h-12 rounded-3xl border-2 border-primary-navyblue-800 capitalize"
        @click="backToProject" />
      <SButton
        :disabled="exportingPDF"
        :text="exportingPDF ? '' : $t('export_pdf')"
        class="h-12 w-36 rounded-3xl bg-accent-purple-400 text-white hover:bg-accent-purple-300"
        @click="generatePDF">
        <div v-if="exportingPDF" class="relative w-full">
          <Loading size="size-8" class="page-center" />
        </div>
      </SButton>
    </div>
    <div ref="pdfContent" class="relative m-auto flex flex-col items-center">
      <ReportScaffold :hide-page-number="true">
        <div class="grid h-5/6 grid-cols-2 items-center gap-spacing-xl">
          <div class="p-spacing-xl">
            <div class="flex items-start">
              <img src="/assets/Advisor.png" class="mr-2 size-10">
              <p class="secu-headline-bold">
                Advisor
              </p>
            </div>
            <div class="mt-spacing-xl flex flex-col">
              <p class="secu-highlight-bold text-[2rem]">
                {{ $t('site_assessment_report') }}
              </p>
              <p v-if="selectedProject" class="secu-headline">
                {{ selectedProject.name }}
              </p>
              <p class="secu-body text-[1.5rem]">
                {{ selectedProject?.account?.name }}
              </p>
              <p class="mb-spacing-sm2 mt-spacing-2xl">
                {{
                  createCombinedAddress(
                    selectedProject?.opportunity?.accountSite,
                  )
                }}
              </p>
              <p>{{ formDate }}</p>
            </div>
          </div>
          <div class="flex h-[553px] items-center justify-center">
            <img
              v-if="selectedProject?.image"
              :src="selectedProject?.image"
              class="h-auto max-h-full w-auto max-w-full rounded-3xl object-contain">
            <img
              v-else
              src="/assets/SecuritasHQ.jpg"
              class="h-full rounded-3xl object-cover">
          </div>
        </div>
      </ReportScaffold>
      <ReportScaffold
        :title="selectedProject?.account?.name"
        :page="getPageNumber('Info page')">
        <ReportInfoPage
          :contact="selectedProject?.opportunity?.contact"
          :user="currentUser ?? undefined" />
      </ReportScaffold>
      <ReportScaffold
        v-for="(content, key) in contentsTable"
        :key
        :page="getPageNumber('Table of contents') + key"
        :title="$t('table_of_contents')">
        <div class="flex flex-col gap-spacing-xl">
          <ReportTableOfContents :page-headers="content" />
        </div>
      </ReportScaffold>
      <ReportScaffold
        :title="$t('site_assessment_method')"
        :page="getPageNumber($t('site_assessment_method'))">
        <div
          class="secu-body-small grid grid-cols-2 items-center gap-spacing-2xl text-justify">
          <div>
            <p>
              {{ $t('site_assessment_method_description') }}
            </p>
            <div class="my-spacing-lg ml-spacing-xl space-y-spacing-sm2">
              <p>
                {{ $t('site_assessment_method_description_1') }}
              </p>
              <p>
                {{ $t('site_assessment_method_description_2') }}
              </p>
              <p>
                {{ $t('site_assessment_method_description_3') }}
              </p>
              <p>
                {{ $t('site_assessment_method_description_4') }}
              </p>
            </div>
            <p>
              {{ $t('site_assessment_method_description_5') }}
            </p>
          </div>
          <div>
            <p class="secu-body-bold">
              {{ $t('iso_31000_risk_management') }}
            </p>
            <img
              src="/assets/ISO.jpg"
              class="mb-spacing-xl mt-spacing-md w-full">
            <img src="/assets/ISOImageLegend.jpg" class="w-full">
          </div>
        </div>
      </ReportScaffold>
      <ReportScaffold
        :title="$t('risk_severity_definitions')"
        :page="getPageNumber($t('risk_severity_definitions'))">
        <ReportSeverityDefinitions />
      </ReportScaffold>
      <ReportScaffold
        :title="$t('risk_matrix')"
        :page="getPageNumber($t('risk_matrix'))">
        <ReportMatrix />
      </ReportScaffold>
      <ReportScaffold
        v-for="(item, index) in summaryItems"
        :key="index"
        :page="getPageNumber($t('overview')) + index"
        :title="$t('overview')">
        <ReportSummary
          :totals-summary="index === 0 ? totalsSummary : null"
          :summary-list="item" />
      </ReportScaffold>
      <div v-for="(risk, riskKey) in riskAssessmentItems.flat()" :key="riskKey">
        <div
          v-for="(scenarios, scenariosKey) in risk.scenarioPagination"
          :key="scenariosKey">
          <ReportScaffold
            :title="scenariosKey === 0 ? $t('site_assessment') : ''"
            :page="
              scenariosKey + getPageNumber(
                `${$tx(risk.areaName)} - ${$tx(risk.riskType.name)}`,
              )
            ">
            <ReportRiskAssessment
              :risk="risk"
              :scenario-page-number="scenariosKey" />
          </ReportScaffold>
        </div>
        <div
          v-for="(scenario, scenarioKey) in risk.scenarios as Scenario[]"
          :key="scenarioKey">
          <ReportScaffold
            :title="$t('site_assessment')"
            :page="getPageNumber(`${$t('scenario')} - ${scenario.id}`)">
            <ReportScenario :risk="risk" :scenario="scenario" :extra="false" />
          </ReportScaffold>
          <ReportScaffold
            v-if="(scenarioImages.get(scenario?.id)?.length ?? 0) >= 3"
            :title="$t('site_assessment')"
            :page="getPageNumber(`${$t('scenario')} - ${scenario.id}`) + 1">
            <ReportScenario :risk="risk" :scenario="scenario" :extra="true" />
          </ReportScaffold>
        </div>
      </div>
      <ReportScaffold hide-page-number>
        <ReportAdvisor />
      </ReportScaffold>
    </div>
  </div>
</template>
